import { AxiosResponse } from 'axios'

export class AccessDenied<ErrorReason = unknown> extends Error {
  public response?: AxiosResponse<ErrorReason>
  constructor(response?: AxiosResponse<ErrorReason>) {
    super()
    this.response = response
  }
}

export function getAccessDeniedText(reason?: string | null) {
  let errorText = '403 - Access Denied'

  if(reason && ['course_deactivated', 'owner_restricted'].includes(reason)) {
    errorText = 'core.error.access_unavailable'
  }

  return errorText
}
